/*
 * @Author: your name
 * @Date: 2021-04-26 14:53:11
 * @LastEditTime: 2021-04-26 15:06:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\util\upfilejs.js
 */
import axios from "axios";
import base from "./base";
let upfilemeth = function(file){
    return new Promise(function(resolve,reject){
        let formdata = new FormData();
        formdata.append("file", file);
        // formdata.append("accessToken", localStorage.getItem("accessToken"));
        formdata.append("accessToken",'c5b3a63ece84aa883836f09204b3e72e');
        
        formdata.append("user_id", localStorage.getItem("id"));
        formdata.append("token", localStorage.getItem("token"));
        formdata.append("requestParams[reqType]", "userinfo");
        formdata.append("requestParams[type]", "1");
        formdata.append("requestParams[platform]", "2");
        formdata.append("requestParams[c_id]", "1");
        axios.defaults.headers.post["X-Requested-With"] = "xmlhttprequest";

        axios
          .post(base.formal + "/user/v1/upload/index", formdata)
          .then((res) => {
            // console.log(res)
            resolve(JSON.parse(res.data).data);
          })
          .catch((res) => {
            reject("upload file of error")
            console.log(res)
          });
    })
}
export default upfilemeth;